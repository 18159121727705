html { scroll-behavior: smooth; }

body {
  margin: 0;
  font-family: 'Lora',
    sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg-light: #f7f3ee;
  --text-light: #273D4D;
  --text-hover: #FD7575;
  --text-hover-20: #642c2c70;
  --bg-dark: #423d39;
  --text-dark: #fae7e7;
  --text-heading2: #ff9a9a;
}

.light {
  background: var(--bg-light)
}
.light h1, h2, h3, a, .body .subtitle{
  color: var(--text-light);
}

.heading1{
  font-size: 32px;
  font-weight: 600;
  font-family: 'Lora';
  @media (max-width: 1200px) {
    font-size: 24px;
  };
}
.heading2{
  font-size: 28px;
  font-weight: 500;
  font-family: 'Lora';
  @media (max-width: 1200px) {
    font-size: 20px;
  };
}
.subtitle {
  font-size: 24px;
  font-weight: 300;
  font-family: 'Montserrat';
  @media (max-width: 1200px) {
    font-size: 18px;
  };
}
.body{
  font-family: 'Poppins';
}
.dark {
  background: var(--bg-dark)
}
.dark h1, h2, h3, a, .body {
  color: var(--text-dark);
}


.container {
  position: relative;
  overflow: visible;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 60px 0;
  @media (max-width: 768px) {
    padding: 20px 0;
  };
}

.wrapper {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    width: 80%;
  };
}

.button {
  display: flex;
  width: 100px;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin-top: 20px;
  border-radius: 99px;
  border: solid 2px var(--text-light);
  padding: 5px 15px;
  font-size: 16px;
  font-family: 'Poppins';
  text-align: center;
  cursor: pointer;
  color: var(--text-light);
  text-decoration: none;
  font-weight: 500;
  transition: all .2s;
  &:hover {
    transform: scale(1.1);
  }
}

.featured {
  color: white;
  background-color: var(--text-hover);
  border: solid 2px var(--text-hover);
  transition: all .2s;
  &:hover {
    transform: scale(1.1);
  }
}

hr {
  width: 100%;
  margin-left: 0;
  color: var(--text-hover);
  border: solid 1px var(--text-hover);
}




.header-container {
  position: relative;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-wrapper {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 80%;
  };
}
.header-logo-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px
}
.header-logo {
  width: 200px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 1024px) {
    display: none;
  };
}

.header-logo-mini {
  height: 100%;
  width: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.header-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  @media (max-width: 1200px) {
    gap: 15px;
  };
  @media (max-width: 1024px) {
    display: none;
  };
}

.header-links-mini {
  width: 30px;
  justify-content: center;
  align-items: center;
  display: none;
  cursor: pointer;
  @media (max-width: 1024px) {
    display: flex;
  };
}
.header-links a {
  text-decoration: none;
  font-size: large;
  font-weight: 400;
  transition: all .2s;
  color: var(--text-light);
  &:hover {
    color: var(--text-hover)
  }
}
.header-mini {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
} 
.header-mini .container {
  height: 100%
}
.header-mini-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.menu-mini-header {
  display: flex;
  width: 100%;
  margin: 20px 0 10% 10px;
  justify-content: space-between;
  height: 40px;
}

.close-button {
  margin: auto 0;
  transform: scale(.8);
  height: 80%;
  cursor: pointer;
}
.mini {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 15px;
  flex-grow: 1;
  gap: 5%;
}
.mini a{
  text-decoration: none;
  font-size: large;
  font-weight: 400;
  transition: all .2s;
  color: var(--text-light);
  &:hover {
    color: var(--text-hover)
  }
}



.hero-content {
  width: 60%;
  @media (max-width: 1532px) {
    width: 100%;
  };
}
.hero-content h2 {
  color: var(--text-light);
}
.hero-content h1 {
  font-size: 84px;
  line-height: 85px;
  font-weight: 600;
  @media (max-width: 1532px) {
    font-size: 64px;
    line-height: 67px;
  };
  @media (max-width: 1024px) {
    font-size: 48px;
    line-height: 50px;
  };
  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 36px;
  };
}
.hero-cta {
  display: flex;
  flex-direction: row;
  gap: 30px;
}




.info {
  justify-content: center;
}
.info-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  @media (max-width: 1532px) {
    padding-left: 20px;
  };
  @media (max-width: 900px) {
    flex-direction: column-reverse;
    padding-left: 0px;
  };
}
.info-content h3 {
  color: var(--text-light);
}
.info-quotes {
  margin-bottom: 100px;
  @media (max-width: 900px) {
    margin-bottom: 50px;
  };
}
.info-quotes h2 {
  color: var(--text-hover);
  position: relative;
  font-style: italic;
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 0;
  @media (max-width: 1532px) {
    font-size: 25px;
  };
  @media (max-width: 1200px) {
    font-size: 20px;
  };
}
.info-content .title {
  margin: auto;
  width: 40%;
  @media (max-width: 1200px) {
    margin: 75px 0;
  };
  @media (max-width: 900px) {
    margin: 50px 0;
    width: 100%;
  };
  @media (max-width: 768px) {
    margin: 25px 0;
    width: 100%;
  };
  @media (max-width: 480px) {
    margin: 025px auto 0 auto;
  };
}
.info-content svg {
  margin: auto;
  width: 30%;
  height: 550px;
  opacity: .9;
  @media (max-width: 1532px) {
    height: 500px;
    width: 40%;
  };
  @media (max-width: 1200px) {
    height: 400px;
  };
  @media (max-width: 900px) {
    height: 250px;
    width: 100%;
  };
}





.info .cifras {
  position: relative;
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1532px) {
    width: 100%;
  };
  @media (max-width: 480px) {
    width: 100%;
    margin: 0;
  };
}
.cifras .grid {
  display: flex;
  flex-direction: column;
  gap: 40px
}
.cifras .heading1 {
  margin: 0;
}
.cifras .item {
  margin: 0;
}
.item h3 {
  margin: 0;
  text-align: center;
  font-weight: 300;
  color: var(--text-light);
  @media (max-width: 900px) {
    text-align: center;
  };
}
.item span {
  color: var(--text-hover);
  font-weight: 500;
}




.cases-content {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.cases-content h1{
  margin: 0 auto 60px auto;
  @media (max-width: 1532px) {
    margin: 0 auto 40px auto;
  };
}
.cases-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 580px) {
    justify-content: center;
  };
}
.case {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  gap: 100px;
  @media (max-width: 1200px) {
    margin-bottom: 20px;
    gap: 20px;
    justify-content: space-between;
  };
  @media (max-width: 580px) {
    margin-bottom: 60px;
    flex-direction: column;
  };
}
.case-image {
  height: 500px;
  width: 500px;
  @media (max-width: 1200px) {
    height: 400px;
  };
  @media (max-width: 580px) {
    width: 80%;
    height: 80%;
    margin: auto;
  };
}
.case-content {
  margin: auto 0;
}
.case h2 {
  margin-bottom: 30px;
  max-width: 300px;
  font-weight: 500;
  @media (max-width: 1532px) {
    font-size: 18px;
  };
  @media (max-width: 1200) {
    font-size: 14px;
  };
}
.case p {
  margin-bottom: 10px;
  max-width: 400px;
  @media (max-width: 1532px) {
    margin: 10px 0;
  };
  @media (max-width: 768px) {
    font-size: 14px;
  };
}
.case-point {
  display: flex;
  align-items: center;
  gap: 10px
}
.case-point span {
  color: var(--text-hover)
}
.case-point .point {
  opacity: .8;
  min-width: 20px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: 1532px) {
    min-width: 20px;
    height: 15px;
  };
}
.reverse-query {
  @media (max-width: 580px) {
    flex-direction: column-reverse;
  };
}




.advantages {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 60%;
  @media (max-width: 1532px) {
    width: 100%;
  };
  @media (max-width: 480px) {
    width: 100%;
    margin: 0;
  };
}
.advantages div {
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 480px) {
    gap: 10px;
  };
}
.advantages p {
  font-size: 24px;
  color: var(--text-light);
  @media (max-width: 1532px) {
    font-size: 20px;
  };
  @media (max-width: 480px) {
    font-size: 16px;
  };
}
.advantages span {
  font-size: 64px;
  color: var(--text-hover);
  @media (max-width: 480px) {
    font-size: 48px;
  };
}
.advantages-content svg {
  width: 40%;
}
.advantages-content {
  display: flex;
  justify-content: space-between;
}


.pricing-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pricing-content hr {
  width: 80%;
  margin: auto;
}
.pricing-content .doubts{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pricing-content .doubts p{
  color: var(--text-light);
  margin: 0;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  gap: 30px;
  margin: 40px 0 100px 0;
  @media (max-width: 1532px) {
    gap: 15px;
    padding: 20px;
  };
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 50px;
  };
  @media (max-width: 1200px) {
    margin-bottom: 60px;
  };

}

.pricing-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 500px;
  width: 300px;
  padding: 10px 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 5px 10px 20px var(--text-hover-20);
  transition: all .3s;
  &:hover {
    transform: scale(1.03);
  };
  @media (max-width: 1532px) {
    padding: 5px 10px;
  };
}

.pricing-card-featured {
  border: 2px solid var(--text-hover);
}

.pricing-badge {
  font-family: 'Poppins';
  position: absolute;
  width: 40%;
  height: 30px;
  background: var(--text-hover);
  top: -20px;
  border-radius: 25px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  color: var(--bg-light);
  font-weight: 500;
}

.pricing-card-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 25px;
}

.pricing-card-header h3 {
  color: var(--text-light);
  font-size: 28px;
  margin: 0;
}

.pricing-card-header p {
  font-size: 28px;
  margin: 0;
  margin: 10px 0;
  color: var(--text-light);
}

.pricing-card-header span {
  font-size: 40px;
  font-weight: 600;
}

.pricing-card-header h4 {
  font-weight: 500;
  color: var(--text-light);
}

.pricing-card-benefits {
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: left;
}

.pricing-card-benefit {
  display: flex;
  gap: 20px;
  align-items: center;
}

.pricing-card-benefit p {
  margin: 0;
  color: var(--text-light);
}

.pricing-card-benefit-check {
  max-width: 20px;
  height: 40px;
}

.pricing-cta {
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  width: 60%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media (max-width: 1200px) {
    width: 100%;
  };
}
.contact-form h3 {
  color: var(--text-light);
}


.form-loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid var(--bg-light);
  border-top-color: var(--text-hover)
}

.container input, textarea, select{
  margin-top: 5px;
  padding: 10px;
  width: 100%;
  font-size: 16px;
  height: 30px;
  border-radius: 5px;
  border: solid 1px var(--text-light);
  color: var(--link-light-hover);
  @media (max-width: 900px) {
    width: 80%;
  };
}

.container label {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container label p {
  color: var(--text-light);
  margin-bottom: 0;
  align-self: center;
}

.container select {
  height: 50px;
  width: 40%;
  margin-top: 20px;
  cursor: pointer;
  color: var(--text-light);
  &:hover {
    background-color: var(--bg-light);
  };
  @media (max-width: 1200px) {
    width: 80%;
  };
  @media (max-width: 768px) {
    width: 90%;
  };
} 
.container textarea {
  height: 90px;
}






.footer-container {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    font-size: 10px;
    padding: 10px;
  };
}

.footer-container p {
  color: var(--text-light);
}

